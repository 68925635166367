import styled, { keyframes } from 'styled-components';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

const slideIn = keyframes`
  from {
    transform: translateX(100px);
  }

  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100px);
  }
`;

const fill = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`;

export const HeroWrapper = styled.div`
  width: 100%;
  height: 565px;
  position: relative;
  overflow: hidden;
  background-color: ${colors.gray[900]};
  color: ${colors.base.white};
  transform: translateZ(0);

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    mix-blend-mode: multiply;
    pointer-events: none;
  }

  &::before {
    height: 200px;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 13.81%,
      #000000 131.01%
    );
    transform: rotate(180deg);
    opacity: 0.8;
  }

  &::after {
    height: 680px;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
  }

  ${atMinWidth.md`
    height: 652px;
  `}

  ${atMinWidth.xl`
    height: 800px;
  `}

  .preload {
    display: none;
  }

  .bg-wrapper {
    position: absolute;
    inset: 0;

    &.initial-state {
      .gatsby-image-wrapper {
        > div {
          display: block;
        }

        img[aria-hidden="true"] {
          display: block;
        }
      }
    }

    .gatsby-image-wrapper {
      position: static;
      display: block;

      > div {
        display: none;
      }

      img {
        pointer-events: none;
        position: absolute;
        inset: 0;
        z-index: 1;
        object-fit: cover;
        object-position: 75% top;

        ${atMinWidth.xl`
          object-position: center top;
        `}

        &[aria-hidden="true"] {
          display: none;
        }
      }
    }
  }

  .container {
    position: relative;
    z-index: 3;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 23.5px;
    padding-right: 23.5px;
    padding-bottom: 18px;

    @media (max-width: 991px) {
      max-width: 100%;
    }

    ${atMinWidth.sm`
      padding-left: 35px;
      padding-right: 35px;
    `}

    ${atMinWidth.md`
      padding-bottom: 30px;
    `}

    ${atMinWidth.lg`
      padding-left: 0;
      padding-right: 0;
    `}

    ${atMinWidth.xl`
      max-width: 1218px;
      padding-left: 23.5px;
      padding-right: 23.5px;
      padding-bottom: 44px;
    `}
  }
`;

export const SlideContentWrapper = styled.div`
  position: relative;
`;

export const SlideContent = styled.div`
  width: 75%;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;

  &.active {
    z-index: 1;

    .heading-kicker {
      animation: ${slideIn} 0.5s ease 0.7s 1;
      animation-play-state: ${({ $isInitialState }) =>
        $isInitialState ? 'paused' : 'running'};
      transition-delay: 0.7s;
      opacity: 1;
    }

    .heading {
      animation: ${slideIn} 0.5s ease 0.6s 1;
      animation-play-state: ${({ $isInitialState }) =>
        $isInitialState ? 'paused' : 'running'};
      transition-delay: 0.6s;
      opacity: 1;
    }

    .cta-wrapper {
      animation: ${slideIn} 0.5s ease 0.5s 1;
      animation-play-state: ${({ $isInitialState }) =>
        $isInitialState ? 'paused' : 'running'};
      transition-delay: 0.5s;
      opacity: 1;
    }
  }

  .heading-kicker {
    ${font('overline', 'sm', '700')}
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
    animation: ${slideOut} 0.5s ease 0.2s 1;
    transition: opacity 0.5s ease 0.2s;
    opacity: 0;

    &.displayed {
      animation: none;
    }
  }

  .heading {
    ${font('display', 'md', '700')}
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
    animation: ${slideOut} 0.5s ease 0.1s 1;
    transition: opacity 0.5s ease 0.1s;
    opacity: 0;

    ${atMinWidth.md`
      ${font('display', 'xl', '700')}
    `}

    ${atMinWidth.xl`
      ${font('display', 'xxl', '700')}
      font-size: 54.93px;
    `}

    &.displayed {
      animation: none;
    }
  }

  .cta-wrapper {
    animation: ${slideOut} 0.5s ease 0s 1;
    transition: opacity 0.5s ease 0s;
    opacity: 0;

    .cta {
      color: ${colors.base.white} !important;
      background: transparent !important;
      border: 1px solid ${colors.base.white} !important;
    }
  }
`;

export const SlideNav = styled.div`
  list-style: none;
  margin: 12px -13px 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;

  ${atMinWidth.sm`
    margin: 28px -16px 0;
  `}

  ${atMinWidth.md`
    margin-top: 40px;
  `}

  ${atMinWidth.xl`
    margin-top: 84px;
  `}
`;

export const SlideNavItem = styled.button`
  flex: 1 1 25%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 12px 13px;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.5s ease;
  text-align: left;
  background: transparent;
  color: ${colors.base.white};
  border: none;
  appearance: none;

  ${atMinWidth.sm`
      padding: 12px 16px;
    `}

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;

    .bar::after {
      /* Subtract 500 from the slide duration so the bar stays orange for a bit before the switch */
      animation: ${fill} ${({ $duration }) => $duration - 500}ms linear 1 both;
    }
  }

  &.stopped {
    .bar::after {
      animation: none;
    }

    &.active {
      .bar::after {
        width: 100%;
      }
    }
  }

  .bar {
    height: 2px;
    width: 100%;
    background-color: ${colors.base.white};
    border-radius: 1px;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      height: 2px;
      border-radius: 1px;
      background-color: ${colors.orange[500]};
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .title {
    ${font('text', 'xs', '700')}
    display: none;

    ${atMinWidth.md`
        display: block;
      `}

    ${atMinWidth.xl`
        ${font('text', 'md', '700')}
      `}
  }
`;
